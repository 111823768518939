{
  "name": "komoot-test",
  "version": "1.4.0",
  "private": true,
  "description": "komoot job application test",
  "license": "MIT",
  "repository": "pke/komoot-test",
  "homepage": "https://komoot.pke.fyi/",
  "scripts": {
    "postinstall": "cd ./docs && npm ci",
    "dev": "parcel ./src/index.html",
    "dev:docs": "cd docs && npm run dev",
    "build:docs": "cd docs && npm run build",
    "_prebuild": "npm run build:docs",
    "build": "parcel build ./src/index.html --dist-dir ./public",
    "test": "jest tests/*.test.js --coverage",
    "testw": "npm t -- --watch",
    "lint:js": "eslint ./src/**/*.js",
    "lint:css": "stylelint ./src/**/*.css"
  },
  "author": {
    "name": "Philipp Kursawe",
    "email": "phil.kursawe+komoot@gmail.com",
    "url": "https://pke.fyi/"
  },
  "dependencies": {
    "core-js": "3.16.2",
    "leaflet": "1.6.0",
    "prop-types": "15.7.2",
    "react": "16.12.0",
    "react-dom": "16.12.0"
  },
  "devDependencies": {
    "@parcel/transformer-image": "2.0.0-rc.0",
    "chai": "4.2.0",
    "enzyme": "3.11.0",
    "eslint": "6.8.0",
    "eslint-plugin-import": "2.19.1",
    "eslint-plugin-jsdoc": "20.0.0",
    "eslint-plugin-mocha": "6.2.2",
    "eslint-plugin-react": "7.17.0",
    "eslint-plugin-react-hooks": "2.3.0",
    "jest": "27.0.6",
    "parcel": "2.0.0-rc.0",
    "postcss": "8.2.15",
    "posthtml-expressions": "1.1.1",
    "stylelint": "13.13.1",
    "stylelint-config-standard": "22.0.0"
  }
}
